import TreeSelectC from "app/_components/TreeSelect";
import { useIntl } from "react-intl";

interface SelectMultiOptionsProps {
  key: number;
  dataOptions: any;
  title: string;
  type: string;
  state?: any;
  updateState: (value: any) => void;
}
export default function SelectMultiOptions({
  key,
  dataOptions,
  title,
  type,
  updateState,
  state,
}: SelectMultiOptionsProps) {
  const intl = useIntl();

  return (
    <div
      key={key}
      className="card-toolbar align-items-center d-flex min-w-100 min-w-sm-250px"
    >
      <div className="bullet bg-secondary h-35px w-1px me-5"></div>
      <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1">
        {intl.formatMessage({ id: title })}:{" "}
      </span>

      {/* begin::Search */}
      <TreeSelectC
        type={type}
        allItems={dataOptions?.map((i: any) => {
          return {
            ...i,
            label: intl.formatMessage({ id: i.label }),
          };
        })}
        state={state}
        updateState={updateState}
        defaultIDs={[]}
        changeAction={(ids: any) => {
          updateState({
            platform: ids,
            page: 1,
          });
        }}
      />
    </div>
  );
}
