import axios, { AxiosResponse } from "axios";
import {
  ContentsQueryResponse,
  MembersQueryResponse,
  TopContentReactionResponse,
  UsersQueryResponse,
  VideosQueryResponse,
} from "./_models";
import {
  ResponseAppDownloadsReport,
  ResponseGetMeReferralLink,
  ResponseLikeAndCommentReport,
  ResponseMultiUserActiveReport,
  ResponseRealTimeUserOnlineReport,
  ResponseVideoViewsReport,
} from "./QueryResponseProvider";
import moment from "moment";

const API_URL = process.env.REACT_APP_API_URL;
const GET_USERS_ACTIVE_URL = `${API_URL}/dashboard/users/active`;
const GET_VIDEOS_REPORT_URL = `${API_URL}/dashboard/videos/report`;
const GET_CONTENTS_REPORT_URL = `${API_URL}/dashboard/articles/report`;
const GET_MEMBERS_REPORT_URL = `${API_URL}/dashboard/users/report`;
const GET_APP_DOWNLOADS_REPORT_URL = `${API_URL}/dashboard/app/download`;
const GET_VIDEO_VIEWS_REPORT_URL = `${API_URL}/dashboard/videos/view-report`;
const GET_MULTI_USER_ACTIVE_REPORT_URL = `${API_URL}/dashboard/app/active-user`;
const GET_REALTIME_USER_ACTIVE_REPORT_URL = `${API_URL}/dashboard/app/realtime-active-user`;
const GET_LIKE_AND_COMMENT_REPORT_URL = `${API_URL}/dashboard/article-like-comment-report`;


const GET_DASHBOARD_CHART = `${API_URL}/dashboard/agency/register`;
const GET_DASHBOARD_REFERRAL_LINK_CLICK_CHART = `${API_URL}/agencies-dashboard/referral-link-clicked-report`;
const GET_ME_REFERRAL_LINK_CLICK = `${API_URL}/agencies/me`;

const getMembersReport = (query: any): Promise<MembersQueryResponse> => {
  return (
    axios
      .get(`${GET_DASHBOARD_REFERRAL_LINK_CLICK_CHART}`, {
        params: query,
      })
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        const membersReport = d.data;
        return {
          data: membersReport,
          total:d.data.count,
          newUser:d.data.new
        };
      })
  );
};


const getLikeAndCommentReport = (
  query: any
): Promise<ResponseLikeAndCommentReport> => {
  return (
    axios
      .get(`${GET_DASHBOARD_CHART}`, {
        params: query,
      })
      //.then((d: AxiosResponse<any>) => d.data);
      .then((d: AxiosResponse<any>) => {
        const likeAndCommentData = d.data;
        return {
          data: likeAndCommentData.reportData,
          total:d.data.count,
          newUser:d.data.new,
          countCurrentDate:d.data.countCurrentDate
        };
      })
  );
};

const getMeReferralLink = (
): Promise<ResponseGetMeReferralLink> => {
  return (
    axios
      .get(`${GET_ME_REFERRAL_LINK_CLICK}`)
      .then((d: AxiosResponse<any>) => {
        return d
      })
  );
};




export {
  // getUserActive,
  // getVideosReport,
  // getContentsReport,
  getMembersReport,
  // getAppDownloadsReport,
  // getVideoViewsReport,
  // getMultiUserActiveViewsReport,
  // getRealTimeUserOnlineReport,
  getLikeAndCommentReport,
  getMeReferralLink,
};
