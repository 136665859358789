import {
  KTCard,
  OPTION_KEYS_HEADER,
  PERMISSIONS_MAP,
  hasPermission,
} from "_metronic/helpers";
import TableSummary from "app/_components/TableSummary";
import { UsersListHeader } from "app/_components/coreListData/header/ListHeader";
import { Table } from "app/_components/table/Table";
import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { usersColumns } from "./table/_columns";

import TableWrapper from "app/_components/coreListData";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "app/_components/coreListData/core/QueryResponseProvider";
import { useAuth } from "app/_modules/auth";
import {
  getPanigationREALUsers,
  getPanigationFAKEUsers,
  getAllReferalChannel,
} from "./core/_requests";
import { PageTitle } from "_metronic/layout/core";

interface Props {
  className?: string;
  data?: any;
  pagination?: any;
  type?: string;
  columns?: any;
}
export const UsersList = ({ data, pagination, type, columns }: Props) => {
  const users: any = useQueryResponseData();
  const dataUser = useMemo(() => users, [users]);
  const [allAgencies, setAllAgencies] = useState<any>([]);
  const [allChannels, setAllReferalChannels] = useState<any>([]);

  const { allPermission, myPermission } = useAuth();

  const intl = useIntl();

  const pag = useQueryResponsePagination();

  const res: any = useQueryResponse();

  let cardList = [
    {
      title: intl.formatMessage({
        id: "USER",
      }),
      value: pag?.total || 0,
    },

    {
      title: intl.formatMessage({
        id: "MALE",
      }),
      value: res?.response?.full_res?.total_male_count || 0,
    },

    {
      title: intl.formatMessage({
        id: "FEMALE",
      }),
      value: res?.response?.full_res?.total_female_count || 0,
    },
  ];

  cardList.sort((a, b) => {
    return b.value - a.value;
  });

  const hasPermissionDelete = hasPermission(
    PERMISSIONS_MAP.REMOVE_USER,
    allPermission,
    myPermission
  );

  let permissionColumns = useMemo(
    () => usersColumns(hasPermissionDelete),
    [allPermission, myPermission]
  );
  const columnUse = useMemo(() => {
    return type === "comment_count" ? columns : permissionColumns;
  }, [permissionColumns]);

  async function getAllReferalChannelFunction() {
    const res = await getAllReferalChannel();
    console.log(res);

    if (res?.status == 200) {
      const options = res?.data?.data?.map((i: any) => {
        return {
          value: i?.name?.toString(),
          label: i?.name?.toString() || "no-name",
        };
      });

      setAllReferalChannels(options);
    }
  }

  useEffect(() => {
    getAllReferalChannelFunction();
  }, []);

  const headerOptions = [
    ...OPTION_KEYS_HEADER.USER_ACTIVE,

    {
      id: "MULTI-OPTIONS",
      title: "CHANNEL",
      type: "referralChannels",
      dataOptions: [...allChannels],
    },
  ];

  return (
    <>
      <PageTitle>
        {intl.formatMessage({
          id: "MEMBER",
        })}
      </PageTitle>
      <UsersListHeader optionsHeader={headerOptions} />
      <KTCard>
        <TableSummary cardList={cardList} />
        <Table
          defineColumns={columnUse}
          data={data || dataUser}
          useQueryResponsePagination={useQueryResponsePagination}
          useQueryResponseLoading={useQueryResponseLoading}
          useQueryRequest={useQueryRequest}
          pagination={pagination}
          // className="frenzy"
        />
      </KTCard>
    </>
  );
};

const UsersListWrapper = () => {
  return (
    <TableWrapper
      getPanigation={getPanigationREALUsers}
      children={<UsersList />}
    ></TableWrapper>
  );
};

export default UsersListWrapper;
