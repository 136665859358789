import { ActiveUserReq, WithChildren } from "_metronic/helpers";
import { FC, createContext, useContext, useState } from "react";

export type QueryUserActiveState = ActiveUserReq;
export type QueryUserActiveRequestContextProps = {
  state: QueryUserActiveState;
  updateState: (updates: any) => void;
};

export const initialUserActiveQueryRequest: QueryUserActiveRequestContextProps =
  {
    state: {
      start_date: new Date(Date.now() - 7 * 86400000)
        .toISOString()
        .substring(0, 10),
      end_date: new Date(Date.now()).toISOString().substring(0, 10),
      type: "week",
    },
    updateState: () => {},
  };

const QueryRequestContext = createContext<any>(initialUserActiveQueryRequest);

const QueryRequestProvider: FC<WithChildren> = ({ children }) => {
  // Start handle state User Active
  const [state, setState] = useState<QueryUserActiveState>(
    initialUserActiveQueryRequest.state
  );

  const updateState = (updates: Partial<QueryUserActiveState>) => {
    console.log("state change");
    const updatedState = { ...state, ...updates } as QueryUserActiveState;
    setState(updatedState);
  };

  const resetState = (updates: Partial<QueryUserActiveState>) => {
    const updatedState = { ...updates } as QueryUserActiveState;
    setState(updatedState);
  };

  return (
    <QueryRequestContext.Provider
      value={{
        state,
        updateState,
        resetState,
      }}
    >
      {children}
    </QueryRequestContext.Provider>
  );
};

const useQueryRequest = () => useContext(QueryRequestContext);

export { QueryRequestProvider, useQueryRequest };
