/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLang } from "_metronic/i18n/Metronici18n";
import EmptyChart from "_metronic/layout/components/empty/EmptyChart";
import ApexCharts, { ApexOptions } from "apexcharts";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
  getCSS,
  getCSSVariableValue,
} from "../../../../_metronic/assets/ts/_utils";
import { useThemeMode } from "../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider";
import { useQueryGetMeReferralResponseData } from "./core/QueryResponseProvider";
import NumberDelay from "app/_components/NumberDelay";
import { KTIcon } from "_metronic/helpers";
import { getMeReferralLink } from "./core/_requests";
import { notification } from "antd";

type Props = {
  className: string;
  isSummary: boolean;
};

const UserReferralLink: React.FC<Props> = ({ className, isSummary }) => {
  const intl = useIntl();
  const [isReferralLink, setIsReferralLink] = useState<any>();

  useEffect(() => {
    getMeReferral();
  }, []);

  const handleCopy = (text?: any) => {
    navigator.clipboard.writeText(text);
    notification.success({
      message: intl.formatMessage({ id: "COPY_CLIPBOARD" }),
      duration: 2,
    });
  };
  const getMeReferral = async () => {
    let result = await getMeReferralLink();
    setIsReferralLink(result?.data);
  };
  // const data = useQueryGetMeReferralResponseData();
  return (
    <div className="col-xxl-12 p-4">
      <div className="row">
        <div className="col-md-6 col-xxl-4">
          <div className="card-body p-0 d-flex flex-column">
            <div className="card-p flex-grow-1 bg-white rounded">
              <label className="d-flex align-items-center fs-5 fw-black mb-4">
                {intl.formatMessage({ id: "AGENCY_REFERRAL_LINK" })}
              </label>
              <div className="input-group d-flex align-items-center gap-2 rounded-right">
                <input
                  className="form-control form-control-lg form-control-solid rounded"
                  name="agencyName"
                  value={isReferralLink?.shortLink}
                  autoFocus
                  readOnly
                />
                <button
                  onClick={() => handleCopy(isReferralLink?.shortLink)}
                  className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary bg-transparent"
                >
                  <KTIcon className="fs-1 cursor-pointer" iconName="copy" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const UserReferralLinkWrapper = ({ className, isSummary = false }: any) => (
  <UserReferralLink isSummary={isSummary} className={className} />
);

export { UserReferralLinkWrapper };
