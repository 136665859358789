import { useLang } from "_metronic/i18n/Metronici18n";
import { DatePicker, Select, Space } from "antd";
import en from "antd/es/date-picker/locale/en_US";
import ko from "antd/es/date-picker/locale/ko_KR";
import "bootstrap-daterangepicker/daterangepicker.css";
import "moment/locale/ko";
import React, { useEffect } from "react";
import { useQueryRequest } from "./core/QueryRequestProvider";

import { rangePresets, rangePresetsEn } from "app/utils";
import dayjs from "dayjs";
import "dayjs/locale/ko";

const { RangePicker } = DatePicker;
const { Option } = Select;
interface FilterByDateProps {
  typeChart?: "content" | "video" | "user" | "";
  updateState?: (value: any) => void;
  state?: any;
}

type PickerType = "time" | "date";
export default function FilterByDate({ updateState, state }: any) {
  const lang = useLang();
  const handleOnChange = (e: any) => {
    const start_date = e && e[0].format("YYYY-MM-DD");
    const end_date = e && e[1].format("YYYY-MM-DD");

    let type = "month";
    if (!e) return;
    if (end_date === start_date) {
      type = "date";
    }

    updateState({ start_date: start_date, end_date: end_date, type: type });
  };

  const [type, setType] = React.useState<PickerType>("date");
  useEffect(() => {
    return () => {
      updateState &&
        updateState({
          start_date: new Date(Date.now() - 7 * 86400000)
            .toISOString()
            .substring(0, 10),
          end_date: new Date(Date.now()).toISOString().substring(0, 10),
        });
    };
  }, []);
  return (
    // <Space
    //   size={"middle"}
    //   className="flex-sm-row w-100 mt-2 justify-content-end"
    // >
    <div
      style={{
        padding: "0 !important",
        // border: "1px solid #d9d9d9",
        borderRadius: 4,
        //border: "1px solid #f9f9f9",
      }}
      className="w-100 rounded d-flex align-items-center flex-wrap filter-header gap-4"
    >
      <RangePicker
        size="middle"
        onChange={(e) => handleOnChange(e)}
        locale={lang === "ko" ? ko : en}
        cellRender={(current, info) => {
          if (info.type !== "date") return info.originNode;
          const style: React.CSSProperties = {};

          return (
            <div className="ant-picker-cell-inner" style={style}>
              {(current as any).date()}
            </div>
          );
        }}
        value={[
          state && state.start_date
            ? dayjs(state.start_date)
            : dayjs().add(-7, "day"),
          state && state.end_date
            ? dayjs(state.end_date)
            : dayjs().add(0, "day"),
        ]}
        disabledDate={(date) => date && date.valueOf() > Date.now()}
        picker={type}
        presets={
          type === "date"
            ? lang === "ko"
              ? rangePresets
              : rangePresetsEn
            : undefined
        }
      />
    </div>
    // </Space>
  );
}
