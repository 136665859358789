import { PageTitle } from "_metronic/layout/core";
import { FilterHeader } from "_metronic/layout/core/FilterHeaderData";
import clsx from "clsx";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { useIntl } from "react-intl";
import FilterByDate from "./components/FilterByDate";
import { useQueryRequest } from "./components/core/QueryRequestProvider";
import { UserPreRegistrationsChartWrapper } from "./components/UserPreRegistrationsChart";
import { UserReferralLinkWrapper } from "./components/UserReferralLink";
import { getAuth } from "../auth";

dayjs.locale("pl");

export default function OverviewPage() {
  const intl = useIntl();
  const { updateState, state } = useQueryRequest();

  return (
    <div className="row g-5 g-xl-10 app-content mb-5 position-relative mb-xl-10 justify-content-start">
      <PageTitle>
        <div
          id="kt_page_title"
          data-kt-swapper="true"
          data-kt-swapper-mode="prepend"
          data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: 5,
              position: "relative",
            }}
          >
            <div className={clsx("menu-title fw-bold fs-5")}>
              {intl.formatMessage({ id: "MENU.DASHBOARD" })}
            </div>
          </div>
        </div>
      </PageTitle>

      <FilterHeader>
        <div className="py-3">
          <FilterByDate
            updateState={updateState}
            state={state}
            typeChart="content"
          />
        </div>
      </FilterHeader>

      <div>
        <div className="row g-5 g-xl-10">
          <UserReferralLinkWrapper />

          <div className="col-xxl-12 p-4">
            <div className="row">
              <div className="col-md-6 col-xxl-4 mb-5">
                <UserPreRegistrationsChartWrapper className={"h-375px"} />
              </div>
            </div>
          </div>
        </div>
        {/* end::Row */}
      </div>
    </div>
  );
}
