import Dashboard from "app/_modules/dashboard";
import UserPage from "app/_modules/member-management";
import { FC, Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";

import { QueryRequestProvider } from "app/_modules/dashboard/components/core/QueryRequestProvider";
import { QueryResponseProvider } from "app/_modules/dashboard/components/core/QueryResponseProvider";
import AgencyModule from "app/_modules/agency-management";

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/" />} />
        <Route
          path="dashboard/*"
          element={
            <QueryRequestProvider>
              <QueryResponseProvider>
                <Dashboard />
              </QueryResponseProvider>
            </QueryRequestProvider>
          }
        />
        {/*  <Route path="contents/*" element={<ContentsPage />} /> */}
        <Route path="user/*" element={<UserPage />} />

        <Route path="agency-management/*" element={<AgencyModule />} />

        <Route
          path="user-management/*"
          element={
            <SuspensedView>
              <UserPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
      "1.0": baseColor,
    },
    barThickness: 3,
    shadowBlur: 3,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
