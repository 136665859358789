import {
  KTCard,
  OPTION_KEYS_HEADER,
  PERMISSIONS_MAP,
  hasPermission,
} from "_metronic/helpers";
import TableSummary from "app/_components/TableSummary";
import { UsersListHeader } from "app/_components/coreListData/header/ListHeader";
import { Table } from "app/_components/table/Table";
import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { usersColumns } from "./table/_columns";

import TableWrapper from "app/_components/coreListData";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import {
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from "app/_components/coreListData/core/QueryResponseProvider";
import { useAuth } from "app/_modules/auth";
// import { getPanigationUsers } from "./core/_requests";
import {
  getPanigationAgency,
  getStatisticAgencyRevenue,
} from "./core/_requests";
import { getUsersInAgency } from "../revenue-management/core/_requests";
import { WITHDRAWAL_REQUEST_STATUS } from "app/constants";

interface Props {
  className?: string;
  data?: any;
  pagination?: any;
  type?: string;
  columns?: any;
}
export const Registrations = ({ data, pagination, type, columns }: Props) => {
  const users: any = useQueryResponseData();
  const dataUser = useMemo(() => users, [users]);
  const { query }: any = useQueryResponse();
  const [statisticData, setStatisticData] = useState<any>();
  const [agencyDataOptions, setagencyDataOptions] = useState<any>([]);
  const [userDataOptions, setUserDataOptions] = useState<any>([]);
  const { state } = useQueryRequest();

  const { allPermission, myPermission } = useAuth();

  const intl = useIntl();

  const pag = useQueryResponsePagination();

  const res: any = useQueryResponse();

  async function getStatistic(query: any) {
    console.log(query);
    const res = await getStatisticAgencyRevenue(query);
    setStatisticData(res);
  }

  useEffect(() => {
    getStatistic(query);
  }, [query]);

  let cardList = [
    {
      title: intl.formatMessage({
        id: "TOTAL-TRANSACTION",
      }),
      value: statisticData?.total,
    },
    {
      title: intl.formatMessage({
        id: "TOTAL-NOW",
      }),
      value: statisticData?.totalNow,
    },
    {
      title: intl.formatMessage({
        id: "TOTAL-AMOUNT",
      }),
      value: statisticData?.totalAmount
        ? `${statisticData?.totalAmount} VND`
        : 0,
    },
  ];

  const hasPermissionDelete = hasPermission(
    PERMISSIONS_MAP.REMOVE_USER,
    allPermission,
    myPermission
  );

  let permissionColumns = useMemo(
    () => usersColumns(hasPermissionDelete),
    [allPermission, myPermission]
  );

  async function getAllUsersAgency(agencyIds: any) {
    const resData = await getUsersInAgency(agencyIds);

    if (resData?.length) {
      const options = resData?.map((i: any) => {
        return {
          value: i?.id?.toString(),
          label: i?.nickName,
        };
      });

      setUserDataOptions(options);
    }
  }

  useEffect(() => {
    getAllUsersAgency(state?.agencyIds);
  }, [state?.agencyIds]);

  const headerOptions = [
    ...OPTION_KEYS_HEADER.WITHDRAW_REVENUE,

    {
      id: "MULTI-OPTIONS",
      title: "USER",
      type: "userIds",
      dataOptions: userDataOptions,
    },

    {
      id: "MULTI-OPTIONS",
      title: "STATUS",
      type: "status",
      dataOptions: WITHDRAWAL_REQUEST_STATUS,
    },
  ];

  return (
    <>
      {data ? null : <UsersListHeader optionsHeader={headerOptions} />}
      <div>
        <KTCard>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
            <TableSummary cardList={cardList} />
          </div>
          <Table
            defineColumns={permissionColumns}
            data={data || dataUser}
            useQueryResponsePagination={useQueryResponsePagination}
            useQueryResponseLoading={useQueryResponseLoading}
            useQueryRequest={useQueryRequest}
            pagination={pagination}
            // className="frenzy"
          />
        </KTCard>
      </div>
    </>
  );
};

const UsersListWrapper = () => (
  <TableWrapper getPanigation={getPanigationAgency}>
    <Registrations />
  </TableWrapper>
);

export default UsersListWrapper;
