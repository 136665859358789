/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from "react-intl";
import { SidebarMenuItem } from "./SidebarMenuItem";

const SidebarMenuMain = () => {
  const intl = useIntl();

  return (
    <>
      <SidebarMenuItem
        to="/dashboard"
        icon="element-11"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-app-indicator"
      />

      <SidebarMenuItem
        to="/user-management"
        title={intl.formatMessage({ id: "MEMBER" })}
        fontIcon="bi-chat-left"
        icon="profile-circle"
      ></SidebarMenuItem>

      <SidebarMenuItem
        to="/agency-management/revenue"
        title={intl.formatMessage({ id: "REVENUE" })}
        fontIcon="bi-chat-left"
        icon="bill"
      ></SidebarMenuItem>

      <SidebarMenuItem
        to="/agency-management/withdraw"
        title={intl.formatMessage({ id: "WITHDRAW" })}
        fontIcon="bi-chat-left"
        icon="dollar"
      ></SidebarMenuItem>
    </>
  );
};

export { SidebarMenuMain };
